import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { client } from 'at-auth'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  return {
    provide: {
      auth: client(config.public['at-auth'] ?? {}),
    },
  }
})
